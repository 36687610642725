var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Breadscrumbs',{staticClass:"header-bread-scrum",attrs:{"items":_vm.breadcrumbs}}),_c('v-layout',{attrs:{"column":""}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-layout',{staticClass:"border-primary rounded-lg px-8 pt-4",attrs:{"column":""}},[_c('v-layout',[_c('v-layout',{staticClass:"pr-6 py-0",attrs:{"column":"","col-6":""}},[_c('label',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t("full_name"))+" "),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.$t("required")))])]),_c('validation-provider',{attrs:{"name":"textRequired","vid":"full_name","rules":_vm.requiredRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","maxlength":_vm.maximumSmallText,"error-messages":errors,"placeholder":_vm.$t('place_holders.employee_fullname')},model:{value:(_vm.fullName),callback:function ($$v) {_vm.fullName=$$v},expression:"fullName"}})]}}],null,true)})],1),_c('v-layout',{staticClass:"pr-6 py-0",attrs:{"column":"","col-6":""}},[_c('label',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t("phone"))+" "),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.$t("required")))])]),_c('validation-provider',{attrs:{"name":"phone","rules":_vm.phoneRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","maxlength":_vm.maximumPhone,"error-messages":errors,"placeholder":_vm.$t('place_holders.phone')},on:{"keypress":function($event){return _vm.isNumber($event)}},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})]}}],null,true)})],1)],1),_c('v-layout',[_c('v-layout',{staticClass:"pr-6 py-0",attrs:{"column":"","col-6":""}},[_c('label',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t("email"))+" "),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.$t("required")))])]),_c('validation-provider',{attrs:{"name":"email","rules":_vm.emailRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","maxlength":_vm.maximumEmail,"error-messages":errors,"placeholder":_vm.$t('place_holders.email')},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1),_c('v-layout',{staticClass:"pr-6 py-0",attrs:{"column":"","col-6":""}},[_c('label',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t("regency"))+" "),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.$t("required")))])]),_c('validation-provider',{attrs:{"name":"selectRequire","vid":"regency","rules":_vm.requiredRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","multiple":"","item-text":"name","item-value":"code","placeholder":_vm.$t('place_holders.regency'),"items":_vm.regencyItems,"error-messages":errors},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"#CCF3FE"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true),model:{value:(_vm.regencySelected),callback:function ($$v) {_vm.regencySelected=$$v},expression:"regencySelected"}})]}}],null,true)})],1)],1)],1),_c('v-layout',{attrs:{"justify-end":""}},[_c('v-btn',{staticClass:"rounded-lg mt-4",attrs:{"color":"primary","type":"submit","disabled":invalid || _vm.disabledBtn}},[_vm._v(" "+_vm._s(_vm.$t("employee_create"))+" ")])],1)],1)]}}])}),_c('dialog-loading',{attrs:{"message":_vm.$t('processing')},model:{value:(_vm.showLoading),callback:function ($$v) {_vm.showLoading=$$v},expression:"showLoading"}}),_c('dialog-notification',{attrs:{"persistent":_vm.persistent,"icon-src":_vm.getIcon,"message":_vm.message},model:{value:(_vm.showNoti),callback:function ($$v) {_vm.showNoti=$$v},expression:"showNoti"}}),_c('dialog-confirm-token-expried',{model:{value:(_vm.showConfirmTokenExpried),callback:function ($$v) {_vm.showConfirmTokenExpried=$$v},expression:"showConfirmTokenExpried"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }