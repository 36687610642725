<template>
  <div>
    <Breadscrumbs :items="breadcrumbs" class="header-bread-scrum" />
    <v-layout column>
      <validation-observer ref="observer" v-slot="{ invalid }">
        <v-form @submit.prevent="submit">
          <v-layout column class="border-primary rounded-lg px-8 pt-4">
            <v-layout>
              <v-layout column col-6 class="pr-6 py-0">
                <label class="text-subtitle-1">
                  {{ $t("full_name") }}
                  <span class="red--text">{{ $t("required") }}</span>
                </label>
                <validation-provider
                  v-slot="{ errors }"
                  name="textRequired"
                  vid="full_name"
                  :rules="requiredRules"
                >
                  <v-text-field
                    v-model="fullName"
                    outlined
                    dense
                    class="rounded-lg"
                    :maxlength="maximumSmallText"
                    :error-messages="errors"
                    :placeholder="$t('place_holders.employee_fullname')"
                  />
                </validation-provider>
              </v-layout>
              <v-layout column col-6 class="pr-6 py-0">
                <label class="text-subtitle-1">
                  {{ $t("phone") }}
                  <span class="red--text">{{ $t("required") }}</span>
                </label>
                <validation-provider
                  v-slot="{ errors }"
                  name="phone"
                  :rules="phoneRules"
                >
                  <v-text-field
                    v-model="phone"
                    outlined
                    dense
                    class="rounded-lg"
                    :maxlength="maximumPhone"
                    :error-messages="errors"
                    :placeholder="$t('place_holders.phone')"
                    @keypress="isNumber($event)"
                  />
                </validation-provider>
              </v-layout>
            </v-layout>
            <v-layout>
              <v-layout column col-6 class="pr-6 py-0">
                <label class="text-subtitle-1">
                  {{ $t("email") }}
                  <span class="red--text">{{ $t("required") }}</span>
                </label>
                <validation-provider
                  v-slot="{ errors }"
                  name="email"
                  :rules="emailRules"
                >
                  <v-text-field
                    v-model="email"
                    outlined
                    dense
                    class="rounded-lg"
                    :maxlength="maximumEmail"
                    :error-messages="errors"
                    :placeholder="$t('place_holders.email')"
                  />
                </validation-provider>
              </v-layout>
              <v-layout column col-6 class="pr-6 py-0">
                <label class="text-subtitle-1">
                  {{ $t("regency") }}
                  <span class="red--text">{{ $t("required") }}</span>
                </label>
                <validation-provider
                  v-slot="{ errors }"
                  name="selectRequire"
                  vid="regency"
                  :rules="requiredRules"
                >
                  <v-select
                    v-model="regencySelected"
                    outlined
                    dense
                    multiple
                    class="rounded-lg"
                    item-text="name"
                    item-value="code"
                    :placeholder="$t('place_holders.regency')"
                    :items="regencyItems"
                    :error-messages="errors"
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip color="#CCF3FE">
                        {{ item.name }}
                      </v-chip>
                    </template>
                  </v-select>
                </validation-provider>
              </v-layout>
            </v-layout>
          </v-layout>

          <v-layout justify-end>
            <v-btn
              color="primary"
              type="submit"
              class="rounded-lg mt-4"
              :disabled="invalid || disabledBtn"
            >
              {{ $t("employee_create") }}
            </v-btn>
          </v-layout>
        </v-form>
      </validation-observer>

      <!-- Dialog loading -->
      <dialog-loading v-model="showLoading" :message="$t('processing')" />

      <!-- notifications -->
      <dialog-notification
        v-model="showNoti"
        :persistent="persistent"
        :icon-src="getIcon"
        :message="message"
      />

      <dialog-confirm-token-expried v-model="showConfirmTokenExpried" />
    </v-layout>
  </div>
</template>

<script>
import Breadscrumbs from '@/components/base/Breadscrumbs'
import DialogLoading from "@/components/dialog/DialogLoading.vue";
import DialogNotification from "@/components/dialog/DialogNotification.vue";
import DialogConfirmTokenExpried from "@/components/dialog/DialogConfirmTokenExpried.vue";
import constants from "@/constants";
import { StringUtils } from "@/helpers/stringUtils";
import { FormUtils } from "@/helpers/formUtils";
import routePaths from "@/router/routePaths";
import { UserService } from "@/services/userService";

export default {
  components: {
    DialogLoading,
    DialogNotification,
    DialogConfirmTokenExpried,
    Breadscrumbs
  },
  data() {
    return {
      requiredRules: {
        required: true,
      },
      fullName: "",
      maximumSmallText: constants.maximumSmallText,
      code: "",
      phone: "",
      maximumPhone: constants.maximumPhone,
      phoneRules: {
        required: true,
        min: constants.minimumPhone,
        max: constants.maximumPhone,
        regex: constants.regExp.phone,
      },
      email: "",
      maximumEmail: constants.maximumEmail,
      emailRules: {
        required: true,
        email: true,
        min: constants.minimumEmail,
        max: constants.maximumEmail,
        regex: constants.regExp.email,
      },
      regencySelected: "",
      regencyItems: [],

      showNoti: false,
      persistent: false,
      typeNoti: constants.typeAlert.warning,
      message: "",
      showLoading: false,
      showConfirmTokenExpried: false,
      breadcrumbs: [
        {
          text: 'employee_list',
          disabled: false,
          isActive: false,
          href: routePaths.EMPLOYEES
        },
        {
          text: 'employee_create',
          disabled: true,
          isActive: true,
        },
      ],
      disabledBtn: false
    };
  },
  computed: {
    getIcon() {
      return StringUtils.getIconSrc(this.typeNoti);
    }
  },
  beforeMount() {
    this.initData();
  },
  methods: {
    async initData() {
      await this.getRoles();
    },
    async submit() {
      var valid = this.$refs.observer.validate();
      if (valid) {
        this.disabledBtn = true
        this.showLoading = true;
        const pars = this.bindRequestPars();
        const { status, data } = await UserService.employeeCreate(pars);
        this.showLoading = false;
        if (status === constants.statusCode.ok && data) {
          this.toggleDialogNoti({
            state: true,
            persistent: true,
            type: constants.typeAlert.success,
            msg: this.$t("success_employee_create"),
          });
          setTimeout(() => {
            this.$router.push(routePaths.EMPLOYEES);
          }, constants.timeOut);
        } else {
          this.toggleDialogNoti({ state: true, msg: data.message });
          setTimeout(() => {
            this.toggleDialogNoti();
          }, constants.timeOut);
          this.disabledBtn = false
        }
      }
    },
    bindRequestPars() {
      const pars = {
        name: this.fullName.trim(),
        phone: this.phone.trim(),
        email: this.email.trim(),
        roles: this.regencySelected,
      };

      return pars;
    },
    async getRoles() {
      this.showLoading = true;
      const { status, data } = await UserService.getRoles();
      this.showLoading = false;
      if (status === constants.statusCode.ok && data) {
        this.regencyItems = data;
      } else {
        this.toggleDialogNoti({ state: true, msg: data.message });
      }
    },
    isNumber(evt) {
      return FormUtils.isNumber(evt);
    },
    toggleDialogNoti(
      pars = {
        state: false,
        persistent: false,
        type: constants.typeAlert.warning,
        msg: "",
      }
    ) {
      this.showNoti = pars.state;
      this.persistent = pars.persistent;
      this.typeNoti = pars.type;
      this.message = pars.msg;
    },
  },
};
</script>
<style src="@/styles/postOffice.scss" lang="scss">
</style>

